import { Context, createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { UseQueryExecute, UseQueryState } from 'urql';

import { useCurrentUserQuery } from 'generated/hooks';
import { UserNode } from 'generated/types';
import { CurrentUserQuery } from 'generated/operations';

import { useTelegramMiniApp } from 'app/_context/TelegramContext';
import { identifyUser, trackOverridePlatforms } from 'app/_tracking';

type AuthContextType = {
  currentUser?: UserNode | null;
  checkIsCurrentUser: (userId: string) => boolean;
  currentUserState: UseQueryState<CurrentUserQuery> | null;
  setCurrentUser: (user: UserNode) => void;
  reFetchCurrentUser: UseQueryExecute,
};

const defaultValues: AuthContextType = {
  currentUser: null,
  currentUserState: null,
  checkIsCurrentUser: () => false,
  setCurrentUser: () => {
  },
  reFetchCurrentUser: () => void 0
};

export const AuthContext: Context<AuthContextType> = createContext(defaultValues);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [res, reFetchCurrentUser] = useCurrentUserQuery();
  const { isEnabledTWA } = useTelegramMiniApp();

  const [currentUser, setCurrentUserRaw] = useState(() => {
    return res?.data?.currentUser;
  });

  const setCurrentUser = useCallback((user: UserNode) => {
    setCurrentUserRaw(user);
    identifyUser(user, trackOverridePlatforms(isEnabledTWA));
  }, [isEnabledTWA]);

  useMemo(() => {
    if (!res.data?.currentUser) return null;
    setCurrentUser(res.data.currentUser);
    return res.data;
  }, [res.data, setCurrentUser]);

  const checkIsCurrentUser = (userId: string) => {
    return currentUser?.id === userId;
  };

  const currentUserState: UseQueryState<CurrentUserQuery> = useMemo(() => res, [res]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserState,
        checkIsCurrentUser,
        setCurrentUser,
        reFetchCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
