import * as amplitude from '@amplitude/analytics-browser';
import { EventOptions } from '@amplitude/analytics-types';
import { CurrentUserType } from 'types';
import { useAuth } from 'contexts/auth';
import { usePathname } from 'next/navigation';
import { resolveRoute } from 'utils/routes';
import { useCommunity } from 'contexts/communityApp';
import { useTelegramMiniApp } from 'app/_context/TelegramContext';

export const initAmplitude = () => {
  const key = process.env.AMPLITUDE_API_KEY;
  if (!key) {
    console.log('Amplitude API key not found');
    return;
  }

  amplitude.init(key, undefined, {
    defaultTracking: {
      pageViews: true,
      sessions: true
    }
  });
};

export const identifyUser = (user: CurrentUserType, platform?: string) => {
  const identifyEvent = new amplitude.Identify();

  identifyEvent.set('name', user.firstName || '-');

  let options: EventOptions = { user_id: trackUserId(user.id) };
  if (platform) options['platform'] = platform;

  amplitude.identify(identifyEvent, options);
};

export interface trackEventType {
  name: string;
  user?: CurrentUserType | null;
  properties?: { [key: string]: any };
  options?: EventOptions;
  path?: string;
}

export const trackUserId = (userId: string | number | undefined) => {
  if (!userId) return;
  return `user_${userId}`;
};

export const trackOverridePlatforms = (isTelegramMiniApp: boolean) => {
  if (isTelegramMiniApp) {
    return 'telegram-mini-app';
  }
};

export const useTracking = () => {
  const { currentUser } = useAuth();
  const { community } = useCommunity();
  const { isEnabledTWA } = useTelegramMiniApp();
  const pathname = usePathname();

  const trackEvent = (props: trackEventType) => {
    const path = props.path || pathname || '';
    const route = resolveRoute(path);
    if (!props.user) {
      props.user = currentUser;
    }

    if (!props.options) props.options = {};
    const customPlatform = trackOverridePlatforms(isEnabledTWA);
    if (customPlatform) props.options['platform'] = customPlatform;

    amplitude.track(
      props.name,
      {
        pageName: route?.name,
        pagePath: path,
        community: community?.subdomain,
        ...props.properties
      },
      {
        user_id: props.user ? trackUserId(props.user.id) : undefined,
        ...props.options
      }
    );
  };

  return {
    trackEvent
  };
};
