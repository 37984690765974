import { Context, createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import Script from 'next/script';

interface ITelegramProvider {
  children: ReactNode;
  isEnabled: boolean;
}

interface ITelegramContext {
  isEnabledTWA: boolean;

  setMainButtonTWA: (title: string, handler: () => void) => void;
  hideMainButtonTWA: (handler: () => void) => void;
  offClickMainButtonTWA: (handler: () => void) => void;
  showBackButtonTWA: (handler: () => void) => void;
  hideBackButtonTWA: (handler: () => void) => void;
}

const defaultValues: ITelegramContext = {
  isEnabledTWA: false,
  setMainButtonTWA: () => {},
  hideMainButtonTWA: () => {},
  offClickMainButtonTWA: () => {},
  showBackButtonTWA: () => {},
  hideBackButtonTWA: () => {},
};

const TelegramContext: Context<ITelegramContext> = createContext(defaultValues);


export const TelegramContextProvider: FC<ITelegramProvider> = (props: ITelegramProvider) => {
  const Telegram = typeof window !== 'undefined' ? (window as any).Telegram : null;

  const expand = Telegram ? Telegram?.WebApp?.expand : null;
  useEffect(() => {
    if (expand) expand();
  }, [expand]);

  useEffect(() => {
    if (!Telegram) return
    Telegram.WebApp.ready();
    Telegram.WebApp.disableVerticalSwipes();
  }, [Telegram]);

  const setMainButton = useCallback((title: string, handler: () => void) => {
    if (!Telegram) return;
    Telegram.WebApp.MainButton.setParams({
      text: title,
      color: "#725EE4",
      text_color: "#fff",
      has_shine_effect: true,
    }).show().enable().onClick(handler);
  }, [Telegram]);

  const hideMainButton = useCallback((handler: () => void) => {
    if (!Telegram) return;
    Telegram.WebApp.MainButton.disable().hide().offClick(handler);
  }, [Telegram]);

  const offClickMainButton = useCallback((handler: () => void) => {
    if (!Telegram) return;
    Telegram.WebApp.MainButton.offClick(handler);
  }, [Telegram]);

  const showBackButton = useCallback((handler: () => void) => {
    if (!Telegram) return;
    Telegram.WebApp.BackButton.show().onClick(handler);
  }, [Telegram]);

  const hideBackButton = useCallback((handler: () => void) => {
    if (!Telegram) return;
    Telegram.WebApp.BackButton.hide().offClick(handler);
  }, [Telegram]);

  const value: ITelegramContext = useMemo(() => ({
    isEnabledTWA: props.isEnabled,
    setMainButtonTWA: setMainButton,
    hideMainButtonTWA: hideMainButton,
    offClickMainButtonTWA: offClickMainButton,
    showBackButtonTWA: showBackButton,
    hideBackButtonTWA:  hideBackButton
  }), [props.isEnabled, setMainButton, hideMainButton, offClickMainButton, showBackButton, hideBackButton]);

  return (
    <TelegramContext.Provider value={value}>
      {props.isEnabled && (
        <Script src={'https://telegram.org/js/telegram-web-app.js'}
                strategy={'beforeInteractive'}
        ></Script>
      )}
      {props.children}
    </TelegramContext.Provider>
  );
};

export function useTelegramMiniApp() {
  return useContext(TelegramContext);
}
